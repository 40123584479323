<template>
  <div>
    <v-card>
      <v-data-table :headers="headers" :options.sync="options" :items="lista" :items-per-page="itemsPerPage"
        :loading="loadingTable" hide-default-footer :key="`list-sales-invoices-${tenantId}`" class="elevation-1">
        <template v-slot:top>
          <ExpandableFilters classButton="py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0" iconButtom="mdi-upload"
            classTitle="col-0" :filters="filters" @datafilters="cargarLista(true)" />
          <!-- <v-col>
            <v-btn
              color="primary"
              small
              class="ma-2 white--text"
              @click="exportar()"
            >
              <v-icon left>mdi-microsoft-excel</v-icon>
              Exportar
            </v-btn>
          </v-col> -->
        </template>
        <template v-slot:item.opcion="{ item }">
          <v-btn icon color="red" target="_blank" :to="`/ver-pago-emitido/${item.payment_id}`">
            <v-icon color="#ff9800">mdi-table-edit</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination class="mt-10" v-model="currentPage" :length="pageCount" @input="handlePageChange"
            total-visible="10"></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <!-- DIALOGO FACTURA -->
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark small color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ cabecera.tipo_factura }}#
            {{
              (cabecera.codigo_establecimiento != null
                ? cabecera.codigo_establecimiento + "-"
                : "") +
              (cabecera.codigo_punto_emision
                ? cabecera.codigo_punto_emision + "-"
                : "") +
              cabecera.invoice_number
            }}
            / {{ cabecera.invoice_id }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">
              Salir
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card class="pl-6 pr-6 pt-6">
          <v-row>
            <v-col md="6" sm="6">
              <v-row>
                <v-col sm="12" md="12" class="pt-0 pb-0">
                  <v-text-field label="Proveedor" required type="text" small disabled
                    v-model="cabecera.participante"></v-text-field>
                </v-col>
                <v-col sm="6" md="6" class="pt-0 pb-0">
                  <v-text-field label="Estado" required type="text" small disabled
                    v-model="cabecera.estado"></v-text-field>
                </v-col>
                <v-col sm="6" md="6" class="pt-0 pb-0">
                  <v-text-field label="Documento origen" required v-if="cabecera.invto_numero != null" type="text" small
                    disabled v-model="cabecera.invto_tipo + ' : ' + cabecera.invto_numero"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6" md="6" class="pt-0 pb-0">
                  <v-text-field label="Fecha de emisión*" required type="text" small disabled
                    v-model="cabecera.inv_fecha"></v-text-field>
                </v-col>
                <v-col sm="6" md="6" class="pt-0 pb-0">
                  <v-text-field label="Fecha de vencimiento " required type="text" small disabled
                    v-model="cabecera.due_date"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" sm="6" class="pt-0 pb-0">
              <v-data-table :headers="headPagos" :options.sync="options" :items="pagos" :items-per-page="100"
                hide-default-footer class="elevation-1"></v-data-table>
              <v-btn v-if="existe_pago_cheque" small @click="solicitarDevCheque">
                Solicitar Devolucion de cheque
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" sm="12">
              <v-data-table :headers="headItems" :options.sync="options" :items="itemsFactura" :items-per-page="100"
                hide-default-footer class="elevation-1">
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="8" sm="8" class="pt-0 pb-0"></v-col>
            <v-col md="4" sm="4" class="pt-0 pb-0">
              <v-col md="12" sm="12" class="pl-12 pt-10 ">
                <v-row>
                  <v-col class="b-1 pt-2 pb-1" style="border-bottom: 1px solid #cecece">Sub total</v-col>
                  <v-col align="right" class="pt-2 pb-1" style="border-bottom: 1px solid #cecece">{{
                    totalesFactura.subTotal }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">Sub total IVA 12%</v-col>
                  <v-col align="right" class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">{{
                    totalesFactura.subTotalConIva }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">Sub total IVA 0%</v-col>
                  <v-col align="right" class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">{{
                    totalesFactura.subTotalSinIva }}</v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">IVA</v-col>
                  <v-col align="right" class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">{{
                    totalesFactura.totalIVA }}</v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">Total</v-col>
                  <v-col align="right" class="pt-4 pb-1" style="border-bottom: 1px solid #cecece">{{ totalesFactura.total
                  }}</v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
    <!-- DIALOGO CHEQUE DEV -->
    <v-dialog v-model="solChequeDev" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Solicitar devolucion de cheque</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formSolChequeDev">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="10" md="10">
                  <v-select v-model="datosDevCheque.fin_account_id" small item-text="fin_account_name"
                    item-value="fin_account_id" :items="cuentasBancarias" label="Cuenta bancaria"
                    :rules="finAccountRule"></v-select>
                </v-col>
                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field v-model="datosDevCheque.ref1" label="Referencia devolucion*" type="text"
                    :append-icon="resRefCheque" :rules="[refRule, refExiste]" append-outer-icon="mdi-magnify" small
                    :suffix="valorChequeEc" @click:append-outer="
                      buscarRefBanco('001316', datosDevCheque.ref1)
                      "></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field v-model="datosDevCheque.refCosto" label="Referencia costo" type="text"
                    append-icon="mdi-magnify" small :suffix="costoChequeEc" :rules="[validarRefCosto]" @click:append="
                      buscarRefBanco('098426', datosDevCheque.refCosto)
                      "></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*campos obligatorios</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="solChequeDev = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" solid class="white--text" @click="enviarSolicitudCheque()">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ExpandableFilters from "../general/ExpandableFilters";

export default {
  name: "PagosProveedoresComponent",
  components: {
    ExpandableFilters,
  },
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Codigo", value: "payment_id" },
      { text: "Fecha", value: "effective_date" },
      { text: "Tipo", value: "tipo_pago" },
      { text: "Forma de pago", value: "forma_pago" },
      { text: "Proveedor", value: "cliente" },
      { text: "Monto", value: "amount", align: "end" },
      { text: "Estado", value: "estado" },
      { text: "Referencia", value: "payment_ref_num" },
    ],
    lista: [],
    options: {},
    dialog: false,
    notifications: false,
    overlay: false,
    cabecera: {
      invoice_date: "",
      participante: "",
      estado: "",
    },
    itemsEstado: [
      { estadoId: "INVOICE_READY", estadoNombre: "Listo" },
      { estadoId: "INVOICE_PAID", estadoNombre: "Pagado" },
    ],
    itemsFactura: [],
    totalesFactura: [],
    headItems: [
      { text: "Opciones", value: "opcion", align: "center", width: 50 },
      { text: "No.", value: "secId", align: "start" },
      { text: "Descripcion", value: "description", align: "start" },
      { text: "Cantidad", value: "cantidad", align: "end" },
      { text: "Precio", value: "valorUnitario", align: "end" },
      { text: "%IVA", value: "pct_iva", align: "end" },
      { text: "IVA", value: "valor_iva", align: "end" },
      { text: "Total", value: "total", align: "end" },
    ],
    headPagos: [
      {
        text: "Tipo",
        value: "payment_method_type",
        align: "center",
        width: 150,
      },
      { text: "Referencia", value: "payment_ref_num", align: "start" },
      { text: "Fecha", value: "effective_date", align: "start" },
      { text: "Monto", value: "amount_applied", align: "start" },
    ],
    filters: [
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-1 pl-0 pl-sm-2",
        v_model: "",
        label: "Código",
        type: "input_text",
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-3 pr-0 pr-sm-2 mb-1",
        v_model: [],
        label: "Fecha",
        type: "input_date_time",
        clearable: true,
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Tipo",
        type: "input_select",
        text: "tipoPagoNombre",
        value: "tipoPagoId",
        clearable: true,
        multiple: true,
        items: [
          { tipoPagoId: "VENDOR_PAYMENT", tipoPagoNombre: "Pago a proveedor" },
          {
            tipoPagoId: "CRUCE_NCC",
            tipoPagoNombre: "Cruce con nota de crédito",
          },
          { tipoPagoId: "DESCTO_PAGO", tipoPagoNombre: "Descuento" },
          { tipoPagoId: "VENDOR_PREPAY", tipoPagoNombre: "Anticipo proveedor" },
          { tipoPagoId: "CUSTOMER_REFUND", tipoPagoNombre: "Reembolos a cliente" },


        ],
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Estado",
        type: "input_select",
        text: "estadoNombre",
        value: "estadoId",
        clearable: true,
        items: [
          { estadoId: "PMNT_NOT_PAID", estadoNombre: "En preparación" },
          { estadoId: "PMNT_SENT", estadoNombre: "Enviado" },
          { estadoId: "PMNT_VOID", estadoNombre: "Cancelado" },
        ],
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2 mb-1",
        v_model: "",
        label: "Proveedor",
        type: "input_text",
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Forma de pago",
        type: "input_select",
        text: "description",
        value: "payment_method_type_id",
        clearable: true,
        items: [],
      },
      {
        cols: 5,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Referencia",
        type: "input_text",
        clearable: true,
        items: [],
      },
    ],
    pagos: [],
    total_pagado: "",
    solChequeDev: false,
    datosDevCheque: { refCosto: "", ref1: "", fin_account_id: "" },
    resRefCheque: "",
    refChequeEc: "",
    valorChequeEc: "",
    refCostoEc: "",
    costoChequeEc: "",
    currentPage: 1,
    pageCount: 1,
    itemsPerPage: 20,
    searchNumber: "",
    searchFechaInv: "",
    searchEstado: "",
    searchCliente: "",
    cuentasBancarias: [],
    finAccountRule: [(v) => !!v || "El campo es obligatorio"],
    refRule: (v) => !!v || "El campo es requerido",
    existe_pago_cheque: false,
  }),
  watch: {
    options: {
      handler() {
        this.cargarLista(false);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId"]),

    ...mapGetters("access", ["btnAbrirCaja"]),

    refExiste() {
      if (this.valorChequeEc == 0 || this.valorChequeEc == "")
        return "No se encontró la referencia";
      else return true;
    },

    validarRefCosto() {
      if (
        this.datosDevCheque.refCosto != "" &&
        (this.costoChequeEc == 0 || this.costoChequeEc == "")
      ) {
        return "No se encontró una transacción";
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarLista(filtrar) {
      if (filtrar == true) {
        this.currentPage = 0;
      }

      this.setLoadingTable(true);
      this.setUrl("pago-realizado");
      this.lista = [];
      this.requestApi({
        method: "GET",
        data: {
          //page: this.currentPage,
          //page_count: this.pageCount,
          page_size: this.itemsPerPage,
          paymentId: this.filters[0].v_model,
          statusId: this.filters[3].v_model,
          paymentDate: this.filters[1].v_model,
          paymentMethodTypeId: this.filters[5].v_model,
          paymentTypeId: this.filters[2].v_model,
          refNum: this.filters[6].v_model,
        },
      })
        .then((res) => {
          //this.lista = res.data.detail.pagos;
          this.lista = res.data._embedded.pago_realizado
          this.pageCount = res.data.page_count;
          this.setLoadingTable(false);
        })
        .finally(() => { 
          this.setLoadingTable(false);
        });
    },

    formInvoice(invid) {
      this.setUrl("invoice/" + invid);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          //console.log(res.data.cabecera, res.data.itemsFactura);
          this.cabecera = res.data.cabecera;
          this.itemsFactura = res.data.itemsFactura;
          this.totalesFactura = res.data.totales;
          this.pagos = res.data.pagos;
          this.total_pagado = res.data.total_pagado;
          this.existe_pago_cheque = res.data.existe_pago_cheque;
          this.dialog = true;
        })
        .then(() => { });
    },

    handlePageChange(value) {
      //this.currentPage = value
      this.cargarLista(false);
    },

    cargarCuentasBancarias() {
      this.setLoadingTable(true);

      this.setUrl("cuentas-bancarias");
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        this.cuentasBancarias = res.data._embedded.cuentas_bancarias;
        this.setLoadingTable(false);
      });
    },

    solicitarDevCheque() {
      this.datosDevCheque = { refCosto: "", ref1: "", fin_account_id: "" };

      this.refChequeEc = "";
      this.valorChequeEc = "";
      this.refCostoEc = "";
      this.costoChequeEc = "";
      this.solChequeDev = true;
    },

    enviarSolicitudCheque() {
      //this.setLoadingTable(true)

      if (!this.$refs.formSolChequeDev.validate()) {
        return false;
      }

      this.setUrl("nota-debito-cheque");
      this.requestApi({
        method: "POST",
        data: {
          ref1: this.refChequeEc,
          ref2: this.refCostoEc,
          party_id: this.cabecera.party_id,
          invoice_id_from: this.cabecera.invoice_id,
          fin_account_id: this.datosDevCheque.fin_account_id,
        },
      }).then((res) => {
        this.solChequeDev = false;
        //this.formInvoice(res.notaDebitoId)

        this.alertNotification({
          param: {
            html: "Nota de debito generada exitosamente",
            timer: 5000,
          },
        });
        this.setLoadingTable(false);
      });
    },

    buscarRefBanco(codigo, doc) {
      this.setUrl("estado-cuenta");
      this.requestApi({
        method: "POST",
        data: {
          codigo: codigo,
          documento: doc,
        },
      }).then((res) => {
        if (codigo == "001316" || codigo == "001305") {
          if (res.data.concil_estado_cuenta_id == "") {
            this.resRefCheque = "mdi-close-circle-outline";
            this.refChequeEc = "";
            this.valorChequeEc = "";
          } else {
            this.resRefCheque = "mdi-check-circle-outline";
            this.refChequeEc = res.data.concil_estado_cuenta_id;
            this.valorChequeEc = res.data.monto;
          }
        }
        if (codigo == "098426") {
          this.refCostoEc = res.data.concil_estado_cuenta_id;
          this.costoChequeEc = res.data.monto;
        }

        //this.setLoadingTable(false)
      });
    },

    /*getEstablishment(){

      this.setUrl('establecimiento')
            this.requestApi({
                method : 'GET',
                data : {}
            }).then(res=>{             

        this.filters[5].items= res.data._embedded.establecimiento

            }).then(()=>{
                
            })

    },*/

    getFormasPago() {
      this.setUrl("tipo-pago/VENDOR_PAYMENT/formas-pago");
      this.requestApi({
        method: "GET",
        data: {

        },
      })
        .then((res) => {
          this.filters[5].items = res.data.detail;
        })
        .then(() => { });
    },

    exportar() {
      this.overlay = true;
      this.setUrl("invoice");
      this.requestApi({
        method: "PUT",
        data: {
          tipo: "SALES_INVOICE",
          cliente: this.filters[4].v_model,
          invoiceNumber: this.filters[0].v_model,
          statusId: this.filters[3].v_model,
          invoiceDate: this.filters[1].v_model,
          establishment: this.filters[5].v_model,
          invoiceType: this.filters[2].v_model,
          page_size: 100000,
          page: 1,
        },
      })
        .then((res) => {
          console.log(res.data._embedded.invoice[0]);
          let link = document.createElement("a");
          link.href = res.data._embedded.invoice[0];
          link.download = "Documentos.xlsx";
          link.click();
        })
        .catch(() => { })
        .then(() => {
          this.overlay = false;
        });
    },
  },

  mounted() {
    this.setTitleToolbar("PAGOS EMITIDOS");
    //this.geEstablishment()
    this.getFormasPago();
    this.cargarCuentasBancarias();
  },
};
</script>
